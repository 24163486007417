import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
var axios = require("axios");

export default {
  // namespaced: true,
  state: {
    cmbDepartment: null,
    cmbClass: null,
    cmbSec: null,
    cmbSubjects: null,
    cmbExamType: null,
    cmbFeeType: null,
    user: null,
    data: null,
    campusID: 1,
  },
  getters: {
    cmbDepartment: (state) => state.cmbDepartment,
    cmbClass: (state) => state.cmbClass,
    cmbSec: (state) => state.cmbSec,
    cmbSubjects: (state) => state.cmbSubjects,
    cmbExamType: (state) => state.cmbExamType,
    cmbFeeType: (state) => state.cmbFeeType,
    user: (state) => state.user,
    data: (state) => state.data,
    campusID: (state) => state.campusID,
  },
  mutations: {
    updateData(state, payload) {
      state.data = payload;
    },
    cmbDepartment(state, val) {
      state.cmbDepartment = val;
    },
    Logout(state, val) {
      state.user = null;
    },
  },
  actions: {
    //async
    async getDepartment(state) {
      var config = {
        method: "get",
        url: "https://myskool.fastech.pk/api/departments",
        headers: {
          Authorization:
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhYmMiLCJqdGkiOiI4NmQyMDQ3NC0xZjg5LTQ5NWItYjc2MC04NzY4NjI1MWJkYTEiLCJleHAiOjE2NzA0MjAyNzksImlzcyI6Im15c2tvb2wuYXBwIiwiYXVkIjoibXlza29vbC5hcHAifQ.jPCDB2X_5FAc9MrT-2oaFrcfM6-fwJ64isfQ_PCxzYM",
        },
      };

      var data = await axios(config);
      state.commit("cmbDepartment", data.data);
    },

    async get(state, val) {
      var config = {
        method: "get",
        url: val.url,
        headers: {
          Authorization: "bearer " + val.token,
        },
      };

      try {
        var data = await axios(config);
        if (data.data.status === "success" && val.message) {
          val.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: val.message,
              variant: "success",
            },
          });
          return true;
        } else if (data !== undefined && val.dashboard) return data.data;
        else if (data !== undefined) return data.data.data;
        else return [];
      } catch (error) {
        return [];
      }
    },

    async getString(state, val) {
      var config = {
        method: "get",
        url: val.url,
        headers: {
          Authorization: "bearer " + val.token,
        },
      };

      try {
        var data = await axios(config);
        if (data !== undefined) return data.data;
        else return [];
      } catch (error) {
        return [];
      }
    },

    async post(state, payload) {
      try {
        var config = {
          method: "post",
          url: payload.url,
          headers: {
            Authorization: "bearer " + payload.token,
          },
          data: payload.body,
        };

        var data = await axios(config);
        if (data.data.status === "success" && payload.validation) {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "InfoIcon",
              text: data.data.message,
              variant: "success",
            },
          });
          return data.data.data;
        } else if (data.data.status === "error" && payload.validation) {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "InfoIcon",
              text: data.data.message,
              variant: "danger",
            },
          });
          return data.data.data;
        }
        if (data.data.status === "success" && payload.upload) {
          return true;
        } else if (data.data.status === "error" && payload.upload) {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "InfoIcon",
              text: data.data.message,
              variant: "danger",
            },
          });
          return data.data.data;
        }
        if (
          (data.data.status === "success" && payload.attendance) ||
          payload.whatsapp
        ) {
          return data.data.data;
        }
        if (data.data.status === "success" && payload.subjects) {
          return data.data.data;
        }
        if (data.data.status === "success" && payload.homework) {
          return data.data.data;
        }
        if (data.data.data === "No data found." && payload.homework) {
          return false;
        }

        if (data.data.status === "success" && payload.savingMarks) {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: payload.message,
              variant: "success",
            },
          });
          return data.data.data;
        }
        if (data.data.status === "success" && payload.returnData) {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: payload.message,
              variant: "success",
            },
          });
          return data.data.data;
        }
        if (data.data.status === "success" && payload.allResponse) {
          return data.data;
        }
        if (data.data.status === "success" && payload.attMarks) {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: payload.message,
              variant: "success",
            },
          });
          return data.data;
        }
        if (data.data.status === "success" && payload.feeReceive) {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: payload.message,
              variant: "success",
            },
          });
          return data.data.slipNo;
        }
        if (
          payload.importing &&
          data.data.status === "success" &&
          payload.lastChunk
        ) {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: payload.message,
              variant: "success",
            },
          });
          return true;
        }
        if (payload.importing && data.data.status === "success") {
          return true;
        }
        if (payload.registration && data.data.status === "success") {
          return true;
        }
        if (data.data.status === "success") {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: payload.message,
              variant: "success",
            },
          });
          return true;
        } else if (payload.savingStudent && data.data.data === "exist") {
          var timeout = 10000;
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: "This GR number is already registerd!",
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return false;
        } else if (data.data.status === "error" && payload.showError) {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "InfoIcon",
              text: data.data.data,
              variant: "danger",
            },
          });
          return false;
        } else {
          var timeout = 10000;
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: "Request failed.",
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return false;
        }
      } catch (error) {
        var timeout = 10000;
        if (
          (payload.savingMarks || payload.attMarks) &&
          error.response.status == 500
        ) {
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: "Grading Policy is not defined correctly.",
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return false;
        } else {
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: error.message,
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return false;
        }
      }
    },
    async postPass(state, payload) {
      try {
        var config = {
          method: "post",
          url: payload.url,
          headers: {
            Authorization: "bearer " + payload.token,
          },
          data: payload.body,
        };
        var data = await axios(config);

        if (data.data.status === "success") {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: payload.message,
              variant: "success",
            },
          });
          return true;
        } else return false;
      } catch (error) {
        return false;
      }
    },

    async postObj(state, payload) {
      try {
        var config = {
          timeout: 300000,
          method: "post",
          url: payload.url,
          headers: {
            Authorization: "bearer " + payload.token,
          },
          data: payload.body,
        };

        var data = await axios(config);
        if (
          (data.data.status === "success" && payload.attendance) ||
          payload.whatsapp
        ) {
          return data.data.data;
        }
        if (data.data.status === "success") {
          if (payload.message != null) {
            payload.context.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckIcon",
                text: payload.message,
                variant: "success",
              },
            });
          }

          return data.data.data;
        } else if (payload.savingStudent && data.data.data === "exist") {
          var timeout = 10000;
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: "This GR number is already registerd!",
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return data.data.data;
        } else {
          var timeout = 10000;
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: "Request failed.",
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return false;
        }
      } catch (error) {
        if (payload.ignoreTimeout && error.response.status == 524) {
          return true;
        } else if (payload.ignoreMessage) {
          return false;
        } else {
          var timeout = 10000;
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: error.message,
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return false;
        }
      }
    },

    async put(state, payload) {
      // console.log(payload);
      var config = {
        method: "put",
        url: payload.url,
        headers: {
          Authorization: "bearer " + payload.token,
        },
        data: payload.body,
      };

      try {
        var data = await axios(config);
        if (payload.importing && data.data.data === "updated") {
          return true;
        } else if (data.data.data === "updated" || data.data.data == "done") {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: payload.message,
              variant: "success",
            },
          });
          return true;
        } else if (payload.savingStudent && data.data.data === "exist") {
          var timeout = 10000;
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: "This GR number is already registerd!",
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return false;
        } else {
          var timeout = 10000;
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: "Request failed.",
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return false;
        }
      } catch (ex) {
        payload.context.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "InfoIcon",
              text: ex.message,
              variant: "danger",
            },
          },
          {
            timeout,
          }
        );
        return false;
      }
    },

    async delete(state, payload) {
      var config = {
        method: "delete",
        url: payload.url,
        headers: {
          Authorization: "bearer " + payload.token,
        },
      };

      try {
        var data = await axios(config);
        if (data.data.status === "success" && payload.grading) {
          return true;
        } else if (data.data.status === "success") {
          payload.context.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: payload.message,
              variant: "success",
            },
          });
          return true;
        } else {
          var timeout = 10000;
          payload.context.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "InfoIcon",
                text: "Unable to delete data.",
                variant: "danger",
              },
            },
            {
              timeout,
            }
          );
          return false;
        }
      } catch (error) {
        var timeout = 10000;
        payload.context.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "InfoIcon",
              text: error.message,
              variant: "danger",
            },
          },
          {
            timeout,
          }
        );
        console.error(error);
        return false;
      }
    },
  },
};
